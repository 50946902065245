import React from 'react';
import AuthSettingsItemHeader from '../AuthSettingsItemHeader';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { InputWord } from '../../../../components/common/Input';
import { DashboardButton } from '../../../../components/common/Button';
import { nicknameType } from '../../../../schema/DashboardSchema';
import UsersService from '../../../../services/users.service';
import { ToastFunc } from '../../../../components/common/Toast';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

type NicknameUpdateProps = {
  nicknameMethods: UseFormReturn<nicknameType>;
  setTabActive: React.Dispatch<React.SetStateAction<string>>;
  setAuthenticationTabPage: React.Dispatch<React.SetStateAction<string>>;
  toastQueueRef: React.MutableRefObject<ToastFunc | undefined>;
};

const NicknameUpdate = ({ nicknameMethods, setTabActive, setAuthenticationTabPage, toastQueueRef }: NicknameUpdateProps) => {
  const usersService = new UsersService();
  const navigate: NavigateFunction = useNavigate();

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const updateNickname = async ({ nickname }: nicknameType) => {
    try {
      await usersService
        .updateUser({
          nickName: nickname,
        })
        .then(() =>
          toastQueueRef?.current?.addToast({
            type: 'SUCCESS',
            description: 'Identifiant mise à jour',
            timer: 5000,
          }),
        );

      setAuthenticationTabPage('');
      setTabActive('authentication');
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 401) {
        navigate(`/login`);
      }
      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <>
      <AuthSettingsItemHeader
        title='Modifier votre identifiant'
        description='Nous vous recommandons de choisir un identifiant personnel ne pouvant pas être deviné : évitez « nom.prénom »'
      />
      <FormProvider {...nicknameMethods}>
        <form onSubmit={nicknameMethods.handleSubmit(updateNickname)}>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='nickname'
              control={nicknameMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Identifiant'
                    type='text'
                    placeholder='ex : SocrateHelenien8'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='mt-6 flex justify-end'>
            <DashboardButton
              type='cancel'
              onClick={() => {
                setAuthenticationTabPage('');
                setTabActive('authentication');
              }}
            />
            <DashboardButton type='confirm' />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default NicknameUpdate;
