import FormHeader from '../../components/forms/FormHeader';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const PasswordResetSuccess = () => {
  const navigate: NavigateFunction = useNavigate();
  return (
    <>
      <FormHeader
        title={`Mot de passe réinitialisé avec succès`}
        subTitle={`Si vous souhaitez retourner à la page de connexion, veuillez cliquer ci-dessous.`}
      />
      <div onClick={() => navigate(`/login`)} className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'>
        Retour à la page de connexion
      </div>
    </>
  );
};

export default PasswordResetSuccess;
