import React from 'react';
import AuthSettingsItemHeader from '../AuthSettingsItemHeader';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { InputWord } from '../../../../components/common/Input';
import { DashboardButton } from '../../../../components/common/Button';
import { updateEmailType } from '../../../../schema/DashboardSchema';
import UsersService from '../../../../services/users.service';
import { ToastFunc } from '../../../../components/common/Toast';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

type EmailUpdate2Props = {
  updateEmailMethods: UseFormReturn<updateEmailType>;
  setTabActive: React.Dispatch<React.SetStateAction<string>>;
  setAuthenticationTabPage: React.Dispatch<React.SetStateAction<string>>;
  toastQueueRef: React.MutableRefObject<ToastFunc | undefined>;
};

const EmailUpdate2 = ({ updateEmailMethods, setTabActive, setAuthenticationTabPage, toastQueueRef }: EmailUpdate2Props) => {
  const usersService = new UsersService();
  const navigate: NavigateFunction = useNavigate();

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const confirmUpdateEmail = async ({ confirmNewEmail }: updateEmailType) => {
    try {
      await usersService.updateUser({
        email: confirmNewEmail,
      });

      toastQueueRef?.current?.addToast({
        type: 'SUCCESS',
        description: 'Email mis à jour',
        timer: 5000,
      });

      setAuthenticationTabPage('');
      setTabActive('authentication');
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 401) {
        navigate(`/login`);
      }
      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <>
      <AuthSettingsItemHeader title='Modifier votre adresse e-mail' description='Modifier votre adresse e-mail de connexion' />
      <FormProvider {...updateEmailMethods}>
        <form onSubmit={updateEmailMethods.handleSubmit(confirmUpdateEmail)}>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='newEmail'
              control={updateEmailMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Nouvelle adresse e-mail'
                    type='text'
                    placeholder='exemple@email.com'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='confirmNewEmail'
              control={updateEmailMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Confirmer la nouvelle adresse e-mail'
                    type='text'
                    placeholder='exemple@email.com'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='mt-6 flex justify-end'>
            <DashboardButton
              type='cancel'
              onClick={() => {
                setAuthenticationTabPage('');
                setTabActive('authentication');
              }}
            />
            <DashboardButton type='confirm' />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default EmailUpdate2;
