import { ReactNode } from 'react';

interface AuthTabPageHeaderProps {
  title: string;
  description: string | ReactNode;
}

const AuthSettingsItemHeader = ({ title, description }: AuthTabPageHeaderProps) => {
  return (
    <>
      <div className='font-bold text-[#21577A]'>{title}</div>
      <div className='border-b border-[#DCE2EC] pb-4 text-[#21577A]'>{description}</div>
    </>
  );
};

export default AuthSettingsItemHeader;
