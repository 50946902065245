import axios from 'axios';

export async function request<T>(params: { method: string; url: string; data?: unknown; token?: string }): Promise<T> {
  const { data } = await axios({
    method: params.method,
    url: import.meta.env.VITE_API_URL + params.url,
    data: params.data,
    headers: {
      'Content-Type': 'application/json',
      ...(localStorage.getItem('accessToken') && {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      }),
    },
    responseType: 'json',
  });

  return data;
}
