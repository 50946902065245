import * as Yup from 'yup';

export const connexionSchema = Yup.object({
  nickNameOrEmail: Yup.string().default('').required('Ce champ est obligatoire'),
  password: Yup.string().default('').required('Ce champ est obligatoire'),
});
export type connexionType = Yup.InferType<typeof connexionSchema>;

export const connexionStepTwoSchema = Yup.object({
  code: Yup.string().default('').required('Ce champ est obligatoire'),
});
export type connexionStepTwoType = Yup.InferType<typeof connexionStepTwoSchema>;
