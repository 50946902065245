import { createRoot } from 'react-dom/client';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { Matomo } from './components/common/Matomo';
import CheckUser from './pages/CkeckUser/CheckUser';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import ResetNickname from './pages/ResetNickname/ResetNickname';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Subscription from './pages/Subscription/Subscription';
import { SupportButton } from './components/common/SupportButton';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Layout from './components/common/Layout';

const App = () => {
  return (
    <>
      <Matomo.Wrapper>
        <Routes>
          <Route element={<Layout Outlet={Outlet} />}>
            <Route path={`/login`} Component={Login} />
            <Route path={`/subscription`} Component={Subscription} />
            <Route path={`/reset-nickname`} Component={ResetNickname} />
            <Route path={`/reset-password`} Component={ResetPassword} />
            <Route path={`/check-user`} Component={CheckUser} />
          </Route>
          <Route path={`/dashboard`} Component={Dashboard} />
          <Route path='/' element={<Navigate to='/login' />} />
          <Route element={<Navigate to='/login' />} />
        </Routes>
        <SupportButton />
      </Matomo.Wrapper>
    </>
  );
};

const app = document.getElementById('root') as HTMLElement;
const root = createRoot(app);

root.render(
  <Matomo.Provider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Matomo.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
