import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthService from '../../services/auth.service';
import UsersService from '../../services/users.service';
import { Seo } from '../../components/common/Seo';
import * as Icon from '../../components/icons';
import { emailSchema, emailType } from '../../schema/CheckUserSchema';
import { BasicButton } from '../../components/common/Button';
import ConnexionFormHeader from '../../components/forms/FormHeader';
import { InputWord } from '../../components/common/Input';
import { ToastFunc, ToastQueue } from '../../components/common/Toast';

const CheckUserComponent = () => {
  const navigate = useNavigate();
  const searchQueryParams = useSearchParams()[0];
  const authService = new AuthService();
  const usersService = new UsersService();
  const toastQueueRef = useRef<ToastFunc>();

  const [checkUserInProgress, setCheckUserInProgress] = useState(true);
  const [pageStep, setPageStep] = useState('emailRequest');
  const [appToken, setAppToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const checkUserMethods = useForm<emailType>({
    resolver: yupResolver(emailSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    checkUserInfo();
  }, []);

  const sendEmailValidation = async ({ email }: emailType) => {
    try {
      const code = searchQueryParams.get('code');

      if (code) {
        usersService.sendEmailValidation(email, appToken);

        setPageStep(`emailSendSuccess`);
      }
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      setErrorMessage(errData.message);
      setPageStep('error');
    }
  };

  const checkUserInfo = async () => {
    try {
      const code = searchQueryParams.get('code');
      const iss = searchQueryParams.get('iss');

      if (code) {
        const loginReturn =
          iss && iss.includes('franceconnect')
            ? await authService.franceConnectCheckUserInfo(code)
            : await authService.proSanteConnectCheckUserInfo(code);

        localStorage.setItem('accessToken', loginReturn.accessToken);
        localStorage.setItem('refreshToken', loginReturn.refreshToken);

        setAppToken(loginReturn.appToken);

        if (loginReturn.hasAppConnectEmail && loginReturn.askMergeAccount) {
          console.log(1);
          setCheckUserInProgress(false);
          setPageStep(`askMergeAccount`);
        } else if (loginReturn.hasEmail) {
          console.log(2);
          // redirect();
        } else {
          console.log(3);
          setCheckUserInProgress(false);
          setPageStep(`emailRequest`);
          toastQueueRef.current?.addToast({ timer: 10000, description: 'Connexion à Pro Santé Connect réussie !', type: 'SUCCESS' });
        }
      }
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      toastQueueRef.current?.addToast({
        timer: 10000,
        description: errData.message,
        type: 'ERROR',
      });
    }
  };

  const redirect = async () => {
    const redirectUrl = localStorage.getItem('redirectUrl');

    if (redirectUrl) {
      window.location.href = redirectUrl.includes('?')
        ? `${redirectUrl}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`
        : `${redirectUrl}?accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`;
    } else {
      window.location.href = `${
        import.meta.env.VITE_SKEZIA_FRONT_URL
      }/login?accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`;
    }
  };

  const mergeAccount = async () => {
    try {
      const code = searchQueryParams.get('code');

      if (code) {
        authService.fcPscMergeAccount(appToken);

        toastQueueRef.current?.addToast({
          timer: 10000,
          description: "Fusion de vos comptes réussie. Vous allez etre redirigé vers l'application.",
          type: 'SUCCESS',
        });
        setTimeout(function () {}, 4000);

        redirect();
      }
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      toastQueueRef.current?.addToast({
        timer: 10000,
        description: errData.message,
        type: 'ERROR',
      });
    }
  };

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      {checkUserInProgress ? (
        <div className='flex h-screen items-center justify-center'>
          <Icon.Spin className='w-[6vw]' />
        </div>
      ) : (
        <div className={`z-[10] w-full rounded-lg bg-white p-6`}>
          {pageStep === 'emailRequest' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Finaliser votre inscription</div>
              <div className='text-center text-gray-primary'>
                Pour terminer votre inscription, veuillez fournir une adresse e-mail afin de l’associer à votre compte
              </div>
              <FormProvider {...checkUserMethods}>
                <form onSubmit={checkUserMethods.handleSubmit(sendEmailValidation)}>
                  <Controller
                    name='email'
                    control={checkUserMethods.control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <InputWord
                          label='Email'
                          type='text'
                          placeholder='Email'
                          value={value}
                          onChangeValue={onChange}
                          errorMessage={error?.message}
                        />
                      </>
                    )}
                  />
                  <BasicButton label='Valider' fullWidth={true} isSubmitButton={true} />
                </form>
              </FormProvider>
            </>
          )}
          {pageStep === 'emailSendSuccess' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Félicitations !</div>
              <div className='text-center text-gray-primary'>
                Votre compte a été crée avec succès ! Un e-mail de confirmation vient de vous être envoyé pour activer votre compte.
              </div>
              <div className='mt-4 flex justify-center'>
                <BasicButton
                  label='Fermer'
                  fullWidth={false}
                  onClick={() => {
                    navigate(`/login`);
                  }}
                />
              </div>
            </>
          )}
          {pageStep === 'askMergeAccount' && (
            <>
              <div className='py-3 text-center text-xl font-bold text-green-dark'>Fusionner vos comptes</div>
              <div className='text-center'>
                Nous avons remarqué que l&apos;adresse mail renseignée lors de votre connexion à FranceConnect ou ProSantéConnect est déjà
                associée à un compte sur notre solution.
                <br />
                <br />
                Pour simplifier l&apos;accès à vos informations, vous pouvez fusionner vos comptes.
              </div>
              <div className='flex justify-center'>
                <span className='mr-4'>
                  <BasicButton
                    label='Se connecter sans fusionner'
                    fullWidth={false}
                    template='white'
                    onClick={() => {
                      setPageStep('emailRequest');
                    }}
                  />
                </span>
                <BasicButton
                  label='Fusionner mes comptes'
                  fullWidth={false}
                  onClick={() => {
                    mergeAccount();
                  }}
                />
              </div>
            </>
          )}
          {pageStep === 'error' && (
            <>
              <ConnexionFormHeader
                title={errorMessage ? '' : `Un problème technique est survenu`}
                subTitle={errorMessage ? errorMessage : `Désolé, une erreur est survenue. Veuillez réessayer plus tard.`}
              />
              <BasicButton label='Se connecter' fullWidth={true} onClick={() => navigate(`/login`)} />
            </>
          )}
        </div>
      )}
      <Seo title={'Connexion FC / PSC'} />
    </>
  );
};

export default CheckUserComponent;
