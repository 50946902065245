import React, { FC, SVGProps } from 'react';

export const Logout: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Menu open'>
        <g id='Group 13977'>
          <path id='Vector 225 (Stroke)' fillRule='evenodd' clipRule='evenodd' d='M5 19L5 5L7 5L7 19L5 19Z' fill='#F6A436' />
          <path
            id='Vector 9 (Stroke)'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.707 5.29297L15.2927 6.70718L19.5856 11.0001H11.9998C11.4476 11.0001 10.9998 11.4478 10.9998 12.0001C10.9998 12.5524 11.4476 13.0001 11.9998 13.0001H19.5856L15.2927 17.293L16.707 18.7072L23.4141 12.0001L16.707 5.29297Z'
            fill='#F6A436'
          />
        </g>
      </g>
    </svg>
  );
};
