import React, { useEffect, useState } from 'react';
import FormHeader from '../../../components/forms/FormHeader';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { InputWord } from '../../../components/common/Input';
import { PasswordConstraint } from '../../../components/common/PasswordConstraint';
import { BasicButton } from '../../../components/common/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { resetPasswordSchema, resetPasswordType } from '../../../schema/ResetPasswordSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import AuthService from '../../../services/auth.service';
import { ToastFunc } from '../../../components/common/Toast';

type PasswordResetFormProps = {
  setPageStep: React.Dispatch<React.SetStateAction<string>>;
  resetPasswordToken: string | null;
  toastQueueRef: React.MutableRefObject<ToastFunc | undefined>;
};

const PasswordResetForm = ({ setPageStep, resetPasswordToken, toastQueueRef }: PasswordResetFormProps) => {
  const navigate: NavigateFunction = useNavigate();
  const authService = new AuthService();

  const resetPasswordMethods = useForm<resetPasswordType>({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onSubmit',
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  type CheckPasswordSecurity = {
    twelveCharacters: boolean;
    oneLowerCaseCharacter: boolean;
    oneUpperCaseCharacter: boolean;
    oneNumberCharacter: boolean;
    oneSpecialCharacter: boolean;
  };

  const [checkPasswordSecurity, setCheckPasswordSecurity] = useState<CheckPasswordSecurity>({
    twelveCharacters: false,
    oneLowerCaseCharacter: false,
    oneUpperCaseCharacter: false,
    oneNumberCharacter: false,
    oneSpecialCharacter: false,
  });

  const resetPassword = async ({ newPassword }: resetPasswordType) => {
    if (resetPasswordToken) {
      try {
        await authService.resetPasswordConfirm(resetPasswordToken, newPassword);

        setPageStep(`resetPasswordSuccess`);
      } catch (error) {
        const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

        if (errData.statusCode === 400) {
          toastQueueRef.current?.addToast({
            timer: 10000,
            description: errData.message,
            type: 'ERROR',
          });
        } else {
          toastQueueRef.current?.addToast({
            type: 'ERROR',
            description: errData.message,
            timer: 5000,
          });
        }
      }
    }
  };

  useEffect(() => {
    let checkSecurity = {
      twelveCharacters: resetPasswordMethods.watch('newPassword').length >= 12,
      oneLowerCaseCharacter: false,
      oneUpperCaseCharacter: false,
      oneNumberCharacter: false,
      oneSpecialCharacter: false,
    };

    for (let i = 0; i < resetPasswordMethods.watch('newPassword').length; i++) {
      checkSecurity = {
        ...checkSecurity,
        oneLowerCaseCharacter:
          checkSecurity.oneLowerCaseCharacter === true ? true : /^[a-z]$/.test(resetPasswordMethods.watch('newPassword').charAt(i)),
        oneUpperCaseCharacter:
          checkSecurity.oneUpperCaseCharacter === true ? true : /^[A-Z]$/.test(resetPasswordMethods.watch('newPassword').charAt(i)),
        oneNumberCharacter:
          checkSecurity.oneNumberCharacter === true ? true : /^\d$/.test(resetPasswordMethods.watch('newPassword').charAt(i)),
        oneSpecialCharacter:
          checkSecurity.oneSpecialCharacter === true ? true : /^\W$/.test(resetPasswordMethods.watch('newPassword').charAt(i)),
      };
    }

    setCheckPasswordSecurity(checkSecurity);
  }, [resetPasswordMethods.watch('newPassword')]);

  return (
    <>
      <FormHeader
        title='Réinitialiser votre mot de passe'
        subTitle='Votre adresse e-mail a été vérifiée. Vous pouvez maintenant procéder à la réinitialisation de votre mot de passe.'
      />
      <FormProvider {...resetPasswordMethods}>
        <form onSubmit={resetPasswordMethods.handleSubmit(resetPassword)}>
          <Controller
            name='newPassword'
            control={resetPasswordMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Mot de passe'
                  type='password'
                  placeholder='Mot de passe'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <PasswordConstraint checkSecurity={checkPasswordSecurity} />
          <Controller
            name='confirmNewPassword'
            control={resetPasswordMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Confirmer votre mot de passe'
                  type='password'
                  placeholder='Mot de passe'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <BasicButton label='Réinitialiser mon mot de passe' fullWidth={true} isSubmitButton={true} />
          <div
            onClick={() => navigate(`/login`)}
            className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'
          >
            Retour à la page de connexion
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default PasswordResetForm;
