import * as Icon from '../../../components/icons';
import { NavigateFunction, useNavigate } from 'react-router-dom';

type DashboardHeaderProps = {
  authenticationTabPage: string;
  setAuthenticationTabPage: React.Dispatch<React.SetStateAction<string>>;
};

const DashboardHeader = ({ authenticationTabPage, setAuthenticationTabPage }: DashboardHeaderProps) => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <div>
      <div className='my-5 flex max-md:justify-center min-md:justify-between'>
        <span>
          {authenticationTabPage && (
            <Icon.ArrowCircleLeft className='absolute left-1 ml-3 min-md:hidden' onClick={() => setAuthenticationTabPage('')} />
          )}
        </span>
        <span className='max-md:ml-0 min-md:ml-10'>
          <Icon.Skezi size={1.5} />
        </span>
        <span
          className='mr-5 flex cursor-pointer max-md:absolute max-md:right-1'
          onClick={() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            navigate(`/login`);
          }}
        >
          <Icon.Logout className='mr-3' />
          <span className='max-md:hidden'>Se déconnecter</span>
        </span>
      </div>
    </div>
  );
};

export default DashboardHeader;
