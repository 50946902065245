import React from 'react';
import FormHeader from '../../../components/forms/FormHeader';
import { FormProvider, Controller, useForm, UseFormReturn } from 'react-hook-form';
import { InputWord } from '../../../components/common/Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { connexionStepTwoSchema, connexionStepTwoType } from '../../../schema/LoginSchema';
import AuthService from '../../../services/auth.service';
import { AxiosError } from 'axios';
import { ToastFunc } from '../../../components/common/Toast';
import { BasicButton } from '../../../components/common/Button';

type TFAFormProps = {
  setPageStep: React.Dispatch<React.SetStateAction<string>>;
  setTokenAndRedirect: (accessToken: string, refreshToken: string) => void;
  connexionMethods: UseFormReturn<{ nickNameOrEmail: string; password: string }, undefined>;
  toastQueueRef: React.MutableRefObject<ToastFunc | undefined>;
};

const TFAForm = ({ setPageStep, setTokenAndRedirect, connexionMethods, toastQueueRef }: TFAFormProps) => {
  const authService = new AuthService();

  const connexionStepTwoMethods = useForm<connexionStepTwoType>({
    resolver: yupResolver(connexionStepTwoSchema),
    mode: 'onSubmit',
    defaultValues: {
      code: '',
    },
  });

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const checkOneTimePassword = async ({ code }: connexionStepTwoType) => {
    try {
      const { accessToken, refreshToken } = await authService.checkOneTimePassword({
        nickNameOrEmail: connexionMethods.watch('nickNameOrEmail'),
        password: connexionMethods.watch('password'),
        oneTimePassword: Number(code),
      });

      setTokenAndRedirect(accessToken, refreshToken);
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      } else {
        setPageStep('error');
      }
    }
  };

  const login = async (resend: boolean) => {
    try {
      const loginReturn = await authService.login({
        nickNameOrEmail: connexionMethods.watch('nickNameOrEmail'),
        password: connexionMethods.watch('password'),
      });

      if (resend) {
        toastQueueRef.current?.addToast({
          timer: 2000,
          description: 'Votre code de vérification a été renvoyé',
          type: 'SUCCESS',
        });
      }

      if (loginReturn.oneTimePasswordActivated) {
        setPageStep('connexionStepTwo');
      } else {
        setTokenAndRedirect(loginReturn.accessToken, loginReturn.refreshToken);
      }
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      } else {
        setPageStep('error');
      }
    }
  };

  return (
    <>
      <FormHeader
        title={`Entrer votre code de vérification de connexion envoyé par SMS`}
        subTitle={`Nous vous avons envoyé un code à 6 chiffres sur votre téléphone. Veuillez entrez le code ci-dessous pour vous connecter.`}
      />
      <FormProvider {...connexionStepTwoMethods}>
        <form onSubmit={connexionStepTwoMethods.handleSubmit(checkOneTimePassword)}>
          <Controller
            name='code'
            control={connexionStepTwoMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Code'
                  type='text'
                  placeholder='Ex : 12345678'
                  actionLabel={
                    <span className='cursor-pointer font-semibold text-green-secondary hover:underline' onClick={() => login(true)}>
                      Renvoyer le code
                    </span>
                  }
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          {/* <div
                    onClick={() => console.log('Contactez l’assistance')}
                    className='mt-1 font-semibold cursor-pointer text-green-secondary'
                  >
                    Un problème de connexion ? Contactez l’assistance.
                  </div> */}
          <BasicButton label='Confirmer' fullWidth={true} isSubmitButton={true} />
          <div
            onClick={() => setPageStep('connexion')}
            className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'
          >
            Retour à la page de connexion
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default TFAForm;
