import { FC } from 'react';
import * as Icon from '../icons';

interface PasswordConstraintProps {
  checkSecurity: {
    twelveCharacters: boolean;
    oneLowerCaseCharacter: boolean;
    oneUpperCaseCharacter: boolean;
    oneNumberCharacter: boolean;
    oneSpecialCharacter: boolean;
  };
}

export const PasswordConstraint: FC<PasswordConstraintProps> = ({ checkSecurity }) => {
  const getPasswordSecurityStrength = () => Object.values(checkSecurity).filter(value => value === true).length;

  return (
    <>
      <div>
        <div className='my-4'>Votre mot de passe doit contenir au moins :</div>
        <div className='mb-1 flex justify-between'>
          <span className='flex w-40'>
            {checkSecurity.twelveCharacters ? <Icon.Right /> : <Icon.Wrong />}
            <span className={`ml-1 ${!checkSecurity.twelveCharacters && 'text-gray-primary'}`}>12 caractères</span>
          </span>
          <span className='flex w-40'>
            {checkSecurity.oneLowerCaseCharacter ? <Icon.Right /> : <Icon.Wrong />}
            <span className={`ml-1 ${!checkSecurity.oneLowerCaseCharacter && 'text-gray-primary'}`}>1 minuscule</span>
          </span>
          <span className='flex w-40'>
            {checkSecurity.oneSpecialCharacter ? <Icon.Right /> : <Icon.Wrong />}
            <span className={`ml-1 ${!checkSecurity.oneSpecialCharacter && 'text-gray-primary'}`}>1 caractère spécial</span>
          </span>
        </div>
        <div className='flex justify-between'>
          <span className='flex w-40'>
            {checkSecurity.oneUpperCaseCharacter ? <Icon.Right /> : <Icon.Wrong />}
            <span className={`ml-1 ${!checkSecurity.oneUpperCaseCharacter && 'text-gray-primary'}`}>1 majuscule</span>
          </span>
          <span className='flex w-40'>
            {checkSecurity.oneNumberCharacter ? <Icon.Right /> : <Icon.Wrong />}
            <span className={`ml-1 ${!checkSecurity.oneNumberCharacter && 'text-gray-primary'}`}>1 chiffre</span>
          </span>
          <span className='flex w-40'></span>
        </div>
        <div className='mt-4'>
          Sécurité : {getPasswordSecurityStrength() < 3 ? 'faible' : getPasswordSecurityStrength() < 5 ? 'moyenne' : 'elevée'}
        </div>
        <div className='my-2 h-2 rounded-full bg-white-light'>
          <div
            className={`h-full rounded-full bg-green-secondary ${getPasswordSecurityStrength() < 3 ? 'w-[0%]' : getPasswordSecurityStrength() < 5 ? 'w-[50%]' : 'w-[100%]'} `}
          />
        </div>
        <div className='text-gray-primary'>
          Evitez les mots de passe qui peuvent être facilement devinés ou que vous utilisez sur d’autres sites
        </div>
      </div>
    </>
  );
};
