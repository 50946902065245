import React, { FC, SVGProps } from 'react';

export const SkemeetAVenirMobile: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={44} height={43} viewBox='0 0 44 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g filter='url(#filter0_d_25700_132032)'>
        <path
          d='M21.0137 38C30.6863 38 38.5274 30.165 38.5274 20.5C38.5274 10.835 30.6863 3 21.0137 3C11.3412 3 3.5 10.835 3.5 20.5C3.5 30.165 11.3412 38 21.0137 38Z'
          fill='white'
        />
      </g>
      <g opacity={0.5} clipPath='url(#clip0_25700_132032)'>
        <g
          style={{
            mixBlendMode: 'luminosity',
          }}
          opacity={0.5}
        >
          <path
            d='M22.35 12.6006H13.71C13.15 12.6006 12.73 12.7306 12.44 12.9806C12.15 13.2306 12 13.5706 12 14.0106C12 14.4506 12.14 14.7706 12.42 15.0206C12.7 15.2806 13.13 15.4006 13.71 15.4006H17.41V27.5906C17.41 28.2806 17.57 28.8006 17.88 29.1506C18.2 29.4906 18.61 29.6706 19.11 29.6706C19.61 29.6706 20.01 29.5006 20.32 29.1606C20.63 28.8206 20.79 28.3006 20.79 27.6006V15.4006H22.34V12.6006H22.35Z'
            fill='#393939'
          />
          <path
            d='M26.5205 12.1502C26.6105 11.2102 27.4305 10.9802 27.8905 11.0002H29.8305C30.5305 11.0102 31.1505 11.4702 31.1505 12.1502V14.3102C31.1505 14.9902 30.5405 15.4202 29.8305 15.4202H29.5405V16.4702C29.5405 16.4702 29.5405 16.4702 29.5305 16.4702L28.8205 15.7702L28.5005 15.4302H28.3605L27.7905 15.4502C27.5005 15.4502 27.2205 15.3802 27.0005 15.2002C26.6405 14.9102 26.4905 14.4302 26.5005 13.8902L26.5205 12.1502Z'
            fill='#979797'
          />
          <path
            d='M27.6319 15.44C27.5719 15.44 27.5219 15.44 27.4719 15.41C27.4219 15.41 27.3719 15.39 27.3219 15.36C27.2419 15.33 27.1819 15.28 27.1419 15.22C27.1219 15.19 27.1019 15.16 27.0819 15.12C26.9619 14.96 26.9619 14.73 27.0819 14.57C27.0619 14.2 27.1419 13.85 27.1619 13.47C27.1719 13.22 27.2119 12.94 27.1919 12.69C27.1919 12.66 27.1919 12.63 27.1819 12.6C27.1219 12.57 27.0619 12.51 27.0219 12.43C27.0219 12.41 27.0019 12.39 26.9919 12.37C26.9919 12.18 26.9919 12 27.0219 11.81C27.0219 11.79 27.0219 11.77 27.0319 11.74C26.9919 11.74 26.9519 11.73 26.9119 11.72V11.71C26.8119 11.71 26.7119 11.71 26.6019 11.71H26.1419L24.4919 11.72C23.7919 11.72 23.1719 12.18 23.1719 12.86V15.02C23.1719 15.7 23.7819 16.13 24.4919 16.13H24.7819V17.18L25.5019 16.48L25.8219 16.14H25.9619L26.7719 16.13C27.2319 16.04 27.5219 15.78 27.6819 15.44C27.6719 15.44 27.6619 15.44 27.6519 15.44H27.6319Z'
            fill='#393939'
          />
          <path
            d='M28.1716 13.0297H27.4016V12.2397C27.4016 11.9497 27.1716 11.7197 26.8916 11.7197C26.6116 11.7197 26.3816 11.9497 26.3816 12.2397V13.0297H25.6116C25.3316 13.0297 25.1016 13.2597 25.1016 13.5497C25.1016 13.8397 25.3316 14.0697 25.6116 14.0697H26.3816V14.8597C26.3816 15.1497 26.6116 15.3797 26.8916 15.3797C27.1716 15.3797 27.4016 15.1497 27.4016 14.8597V14.0697H28.1716C28.4516 14.0697 28.6816 13.8397 28.6816 13.5497C28.6816 13.2597 28.4516 13.0297 28.1716 13.0297Z'
            fill='white'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d_25700_132032'
          x={0.5}
          y={0}
          width={43.0273}
          height={43}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_25700_132032' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_25700_132032' result='shape' />
        </filter>
        <clipPath id='clip0_25700_132032'>
          <rect width={19.15} height={18.66} fill='white' transform='translate(12 11)' />
        </clipPath>
      </defs>
    </svg>
  );
};
