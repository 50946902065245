import React from 'react';
import { Modal } from '../../../components/common/Modal';

type EmailModalProps = {
  setAuthenticationTabPage: React.Dispatch<React.SetStateAction<string>>;
  modaleVerificationEmailSend: boolean;
  setModaleVerificationEmailSend: React.Dispatch<React.SetStateAction<boolean>>;
};

const EmailModal = ({ setAuthenticationTabPage, modaleVerificationEmailSend, setModaleVerificationEmailSend }: EmailModalProps) => {
  return (
    <Modal modalOpen={modaleVerificationEmailSend} setModalOpenToFalse={() => setModaleVerificationEmailSend(false)} modalWidth='w-[70%]'>
      <div className='text-center text-[#21577A]'>
        <div className='text-lg font-bold'>Mail de vérification envoyé</div>
        <div className='my-2'>Veuillez vérifier votre boîte de réception pour trouver un e-mail contenant un lien de vérification.</div>
        <div>
          Si vous ne pouvez plus accéder à votre adresse e-mail, veuillez contacter votre fournisseur de messagerie pour retrouver
          l&apos;accès.
        </div>
      </div>
      <div className='mt-4 flex justify-center'>
        <button
          className='mr-4 rounded-[2rem] border border-[#F6A436] bg-white px-4 py-2 text-[#F6A436]'
          onClick={() => {
            setAuthenticationTabPage('');
            setModaleVerificationEmailSend(false);
          }}
        >
          Annuler
        </button>
        <button
          className='rounded-[2rem] bg-[#F6A436] px-4 py-2 text-white'
          onClick={() => {
            setAuthenticationTabPage('');
            setModaleVerificationEmailSend(false);
          }}
        >
          Compris
        </button>
      </div>
    </Modal>
  );
};

export default EmailModal;
