import { yupResolver } from '@hookform/resolvers/yup';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { connexionSchema, connexionType } from '../../schema/LoginSchema';
import { Seo } from '../../components/common/Seo';
import { ToastFunc, ToastQueue } from '../../components/common/Toast';
import ErrorForm from '../../components/forms/ErrorForm';
import LoginForm from './forms/LoginForm';
import TFAForm from './forms/TFAForm';

const LoginComponent = () => {
  const searchQueryParams = useSearchParams()[0];
  const [pageStep, setPageStep] = useState<string>('connexion');
  const toastQueueRef = useRef<ToastFunc>();

  const connexionMethods = useForm<connexionType>({
    resolver: yupResolver(connexionSchema),
    mode: 'onSubmit',
    defaultValues: {
      nickNameOrEmail: '',
      password: '',
    },
  });

  const setTokenAndRedirect = async (accessToken: string, refreshToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    const redirectUrl = searchQueryParams.get('redirectUrl');

    if (redirectUrl) {
      window.location.href = redirectUrl.includes('?')
        ? `${redirectUrl}&accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`
        : `${redirectUrl}?accessToken=${localStorage.getItem('accessToken')}&refreshToken=${localStorage.getItem('refreshToken')}`;
    } else {
      window.location.href = `${import.meta.env.VITE_SKEZIA_FRONT_URL}/login?accessToken=${localStorage.getItem(
        'accessToken',
      )}&refreshToken=${localStorage.getItem('refreshToken')}`;
    }
  };

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      <div className='z-[10] w-full rounded-lg bg-white p-10'>
        {
          {
            connexion: (
              <LoginForm
                setPageStep={setPageStep}
                setTokenAndRedirect={setTokenAndRedirect}
                connexionMethods={connexionMethods}
                toastQueueRef={toastQueueRef}
              />
            ),
            connexionStepTwo: (
              <TFAForm
                setPageStep={setPageStep}
                setTokenAndRedirect={setTokenAndRedirect}
                connexionMethods={connexionMethods}
                toastQueueRef={toastQueueRef}
              />
            ),
            error: <ErrorForm onClick={() => setPageStep('connexion')} />,
          }[pageStep]
        }
      </div>
      <Seo title={'Connexion'} />
    </>
  );
};

export default LoginComponent;
