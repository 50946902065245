import React, { FC, SVGProps } from 'react';

export const ArrowRight: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={8} height={14} viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path id='Vector 7' d='M1 13L7 7L1 0.999999' stroke='#57A1A4' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default ArrowRight;
