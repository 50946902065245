import React from 'react';
import AuthSettingsItemHeader from '../AuthSettingsItemHeader';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { InputWord } from '../../../../components/common/Input';
import { DashboardButton } from '../../../../components/common/Button';
import { emailType } from '../../../../schema/DashboardSchema';
import { AxiosError } from 'axios';
import UsersService from '../../../../services/users.service';
import { ToastFunc } from '../../../../components/common/Toast';
import { NavigateFunction, useNavigate } from 'react-router-dom';

type EmailUpdate1Props = {
  emailMethods: UseFormReturn<emailType>;
  setTabActive: React.Dispatch<React.SetStateAction<string>>;
  setAuthenticationTabPage: React.Dispatch<React.SetStateAction<string>>;
  setModaleVerificationEmailSend: React.Dispatch<React.SetStateAction<boolean>>;
  toastQueueRef: React.MutableRefObject<ToastFunc | undefined>;
};

const EmailUpdate1 = ({
  emailMethods,
  setTabActive,
  setAuthenticationTabPage,
  setModaleVerificationEmailSend,
  toastQueueRef,
}: EmailUpdate1Props) => {
  const usersService = new UsersService();
  const navigate: NavigateFunction = useNavigate();

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const confirmSendingEmailForUpdateEmail = async ({ email }: emailType) => {
    try {
      await usersService.sendEmailForUpdateEmail(email);

      setModaleVerificationEmailSend(true);
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 401) {
        navigate(`/login`);
      }
      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <>
      <AuthSettingsItemHeader
        title='Modifier votre adresse e-mail'
        description={
          <span>
            En cliquant sur le bouton &quot;Modifier&quot;, un e-mail de vérification sera envoyé à votre adresse e-mail afin de vérifier
            votre identité.
            <br /> Une fois votre adresse e-mail vérifiée, vous pourrez finaliser la modification en saisissant votre nouvelle adresse
            e-mail.
          </span>
        }
      />
      <FormProvider {...emailMethods}>
        <form onSubmit={emailMethods.handleSubmit(confirmSendingEmailForUpdateEmail)}>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='email'
              control={emailMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='E-mail'
                    type='text'
                    placeholder='exemple@email.com'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='mt-6 flex justify-end'>
            <DashboardButton
              type='cancel'
              onClick={() => {
                setAuthenticationTabPage('');
                setTabActive('authentication');
              }}
            />
            <DashboardButton type='confirm' />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default EmailUpdate1;
