import * as Yup from 'yup';

export const emailSchema = Yup.object({
  email: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Le format attendu est de type email. Ex : azerty@skezi.eu',
    ),
});
export type emailType = Yup.InferType<typeof emailSchema>;

export const updateEmailSchema = Yup.object({
  newEmail: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Le format attendu est de type email. Ex : azerty@skezi.eu',
    ),
  confirmNewEmail: Yup.string()
    .default('')
    .oneOf([Yup.ref('newEmail')], 'Les emails ne correspondent pas'),
});
export type updateEmailType = Yup.InferType<typeof updateEmailSchema>;

export const nicknameSchema = Yup.object({
  nickname: Yup.string().default('').required('Ce champ est obligatoire').min(8, 'Votre identifiant doit avoir un minimum de 8 caractères'),
});
export type nicknameType = Yup.InferType<typeof nicknameSchema>;

export const updatePasswordSchema = Yup.object({
  actualPassword: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?=.{12,})/,
      "Votre mot de passe doit contenir 12 caractères minimum, ainsi qu'au moins 1 de chacun de ces éléments : lettre minuscule, lettre majuscule, chiffre, caractère spécial.",
    ),
  newPassword: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?=.{12,})/,
      "Votre mot de passe doit contenir 12 caractères minimum, ainsi qu'au moins 1 de chacun de ces éléments : lettre minuscule, lettre majuscule, chiffre, caractère spécial.",
    ),
  confirmNewPassword: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .oneOf([Yup.ref('newPassword')], 'Les mots de passe ne correspondent pas'),
});
export type updatePasswordType = Yup.InferType<typeof updatePasswordSchema>;

export const doubleAuthenticationSchema = Yup.object({
  activeSmsAuthentication: Yup.boolean().default(false),
  cellphone: Yup.string()
    .default('')
    .matches(/(^$)|(^[0-9]{10}$)/, 'Le numéro doit etre au format 10 chiffres')
    .when('activeSmsAuthentication', {
      is: true,
      then: schema => schema.nonNullable().required('Veuillez renseigner votre numéro de mobile'),
    }),
});
export type doubleAuthenticationType = Yup.InferType<typeof doubleAuthenticationSchema>;
