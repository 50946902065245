import { request } from './fetcher';

interface User {
  email: string;
  nickName: string;
  password: string;
  subscribeNewsletter: boolean;
}

export default class UsersService {
  async createUser(user: User): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/create`,
      data: {
        email: user.email,
        nickName: user.nickName,
        password: user.password,
        subscribeNewsletter: user.subscribeNewsletter,
      },
    });
  }

  async sendEmailValidation(email: string, appToken: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/send-email-validation`,
      data: {
        email,
        appToken,
      },
    });
  }

  async validateAccount(updateEmailToken: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/validate-account`,
      data: { updateEmailToken },
    });
  }

  async sendEmailForUpdateEmail(email: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/send-email-for-update-email`,
      data: {
        email: email,
      },
    });
  }

  async updateUser(data: { email?: string; nickName?: string; password?: string }) {
    return await request({
      method: 'patch',
      url: `/users`,
      data,
    });
  }

  async updatePassword(data: { oldPassword: string; newPassword: string }) {
    return await request({
      method: 'patch',
      url: `/users/password`,
      data,
    });
  }

  async updateDoubleAuthentication(oneTimePasswordActivated: boolean, cellphone: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/update-double-authentication`,
      data: {
        oneTimePasswordActivated,
        cellphone,
      },
    });
  }

  async getDoubleAuthentication(): Promise<{ oneTimePasswordActivated: boolean; cellphone: string }> {
    return await request({
      method: 'get',
      url: `/users/double-authentication`,
    });
  }

  async sendSupportMessage(name: string, email: string, description: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/users/send-support-message`,
      data: {
        name,
        email,
        description,
      },
    });
  }
}
