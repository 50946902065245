import React, { useEffect, useState } from 'react';
import AuthSettingsItemHeader from '../AuthSettingsItemHeader';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { InputWord } from '../../../../components/common/Input';
import { PasswordConstraint } from '../../../../components/common/PasswordConstraint';
import { DashboardButton } from '../../../../components/common/Button';
import { updatePasswordType } from '../../../../schema/DashboardSchema';
import UsersService from '../../../../services/users.service';
import { ToastFunc } from '../../../../components/common/Toast';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

type PasswordUpdateProps = {
  updatePasswordMethods: UseFormReturn<updatePasswordType>;
  setTabActive: React.Dispatch<React.SetStateAction<string>>;
  setAuthenticationTabPage: React.Dispatch<React.SetStateAction<string>>;
  toastQueueRef: React.MutableRefObject<ToastFunc | undefined>;
};

const PasswordUpdate = ({ updatePasswordMethods, setTabActive, setAuthenticationTabPage, toastQueueRef }: PasswordUpdateProps) => {
  const usersService = new UsersService();
  const navigate: NavigateFunction = useNavigate();

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  type CheckPasswordSecurity = {
    twelveCharacters: boolean;
    oneLowerCaseCharacter: boolean;
    oneUpperCaseCharacter: boolean;
    oneNumberCharacter: boolean;
    oneSpecialCharacter: boolean;
  };

  const [checkPasswordSecurity, setCheckPasswordSecurity] = useState<CheckPasswordSecurity>({
    twelveCharacters: false,
    oneLowerCaseCharacter: false,
    oneUpperCaseCharacter: false,
    oneNumberCharacter: false,
    oneSpecialCharacter: false,
  });

  useEffect(() => {
    let checkSecurity = {
      twelveCharacters: updatePasswordMethods.watch('newPassword').length >= 12,
      oneLowerCaseCharacter: false,
      oneUpperCaseCharacter: false,
      oneNumberCharacter: false,
      oneSpecialCharacter: false,
    };

    for (let i = 0; i < updatePasswordMethods.watch('newPassword').length; i++) {
      checkSecurity = {
        ...checkSecurity,
        oneLowerCaseCharacter:
          checkSecurity.oneLowerCaseCharacter === true ? true : /^[a-z]$/.test(updatePasswordMethods.watch('newPassword').charAt(i)),
        oneUpperCaseCharacter:
          checkSecurity.oneUpperCaseCharacter === true ? true : /^[A-Z]$/.test(updatePasswordMethods.watch('newPassword').charAt(i)),
        oneNumberCharacter:
          checkSecurity.oneNumberCharacter === true ? true : /^\d$/.test(updatePasswordMethods.watch('newPassword').charAt(i)),
        oneSpecialCharacter:
          checkSecurity.oneSpecialCharacter === true ? true : /^\W$/.test(updatePasswordMethods.watch('newPassword').charAt(i)),
      };
    }

    setCheckPasswordSecurity(checkSecurity);
  }, [updatePasswordMethods.watch('newPassword')]);

  const updatePassword = async ({ actualPassword, confirmNewPassword }: updatePasswordType) => {
    try {
      await usersService
        .updatePassword({
          oldPassword: actualPassword,
          newPassword: confirmNewPassword,
        })
        .then(() =>
          toastQueueRef?.current?.addToast({
            type: 'SUCCESS',
            description: 'Mot de passe mis à jour',
            timer: 5000,
          }),
        );

      setAuthenticationTabPage('');
      setTabActive('authentication');
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 401) {
        navigate(`/login`);
      }
      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      }
    }
  };

  return (
    <>
      <AuthSettingsItemHeader
        title='Modifier votre mot de passe'
        description='Pour votre sécurité, votre mot de passe doit être strictement personnel. Ne le divulguez à personne.'
      />
      <FormProvider {...updatePasswordMethods}>
        <form onSubmit={updatePasswordMethods.handleSubmit(updatePassword)}>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='actualPassword'
              control={updatePasswordMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Mot de passe actuel'
                    type='password'
                    placeholder='Mot de passe'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='newPassword'
              control={updatePasswordMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Nouveau mot de passe'
                    type='password'
                    placeholder='Mot de passe'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <PasswordConstraint checkSecurity={checkPasswordSecurity} />
          <div className='w-1/2 max-md:w-full'>
            <Controller
              name='confirmNewPassword'
              control={updatePasswordMethods.control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <InputWord
                    label='Confirmer le nouveau mot de passe'
                    type='password'
                    placeholder='Mot de passe'
                    value={value}
                    onChangeValue={onChange}
                    errorMessage={error?.message}
                  />
                </>
              )}
            />
          </div>
          <div className='mt-6 flex justify-end'>
            <DashboardButton
              type='cancel'
              onClick={() => {
                setAuthenticationTabPage('');
                setTabActive('authentication');
              }}
            />
            <DashboardButton type='confirm' />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default PasswordUpdate;
