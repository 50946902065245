import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { BasicButton } from '../../../components/common/Button';
import { InputWord } from '../../../components/common/Input';
import { ToastFunc } from '../../../components/common/Toast';
import FormHeader from '../../../components/forms/FormHeader';
import { connexionType } from '../../../schema/LoginSchema';
import AuthService from '../../../services/auth.service';
import UsersService from '../../../services/users.service';

type LoginFormProps = {
  setPageStep: React.Dispatch<React.SetStateAction<string>>;
  setTokenAndRedirect: (accessToken: string, refreshToken: string) => void;
  connexionMethods: UseFormReturn<{ nickNameOrEmail: string; password: string }, undefined>;
  toastQueueRef: React.MutableRefObject<ToastFunc | undefined>;
};

const LoginForm = ({ setPageStep, setTokenAndRedirect, connexionMethods, toastQueueRef }: LoginFormProps) => {
  const navigate: NavigateFunction = useNavigate();
  const searchQueryParams = useSearchParams()[0];
  const authService = new AuthService();
  const usersService = new UsersService();

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const getProSanteConnectAuthenticationUrl = async () => {
    try {
      const data = await authService.getProSanteConnectLink();
      document.location.href = data.authenticationUrl;
    } catch (error) {
      setPageStep('error');
    }
  };

  const getFranceConnectAuthenticationUrl = async () => {
    try {
      const data = await authService.getFranceConnectLink();
      document.location.href = data.authenticationUrl;
    } catch (error) {
      setPageStep('error');
    }
  };

  const loginWithForm = async ({ nickNameOrEmail, password }: connexionType) => {
    try {
      const loginReturn = await authService.login({
        nickNameOrEmail: nickNameOrEmail,
        password: password,
      });

      if (loginReturn.oneTimePasswordActivated) {
        setPageStep('connexionStepTwo');
      } else {
        setTokenAndRedirect(loginReturn.accessToken, loginReturn.refreshToken);
      }
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;
      const messages: Record<string, string> = {
        INVALID_CREDENTIALS:
          "La combinaison de votre adresse e-mail et mot de passe est incorrecte. Assurez vous de vous être inscrit, ou d'avoir saisi les bons identifiants.",
        ACCOUNT_NOT_CONFIRMED: 'Vous devez confirmer votre compte avant de pouvoir vous connecter.',
      };

      toastQueueRef.current?.addToast({
        timer: 10000,
        description:
          messages[errData.message] ?? 'Erreur : Veuillez actualiser votre page. Si le problème persiste, merci de contacter le support',
        type: 'ERROR',
      });
    }
  };

  const validateAccount = async (mailActivatedToken: string) => {
    try {
      await usersService.validateAccount(mailActivatedToken);

      toastQueueRef.current?.addToast({
        timer: 10000,
        description: 'Votre compte a été validé avec succès. Vous pouvez maintenant vous connecter.',
        type: 'SUCCESS',
      });
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      } else {
        setPageStep('error');
      }
    }
  };

  const mergeAccount = async (mergeAccountToken: string) => {
    try {
      await authService.fcPscMergeAccount(mergeAccountToken);

      toastQueueRef.current?.addToast({
        timer: 10000,
        description: 'Votre compte a été associé avec succès. Vous pouvez maintenant vous connecter.',
        type: 'SUCCESS',
      });
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      } else {
        setPageStep('error');
      }
    }
  };

  useEffect(() => {
    const redirectUrl = searchQueryParams.get('redirectUrl');
    localStorage.setItem('redirectUrl', redirectUrl ? redirectUrl : '');

    const mailActivatedToken = searchQueryParams.get('mailActivatedToken');
    if (mailActivatedToken) {
      validateAccount(mailActivatedToken);
    }

    const mergeAccountToken = searchQueryParams.get('mergeAccountToken');
    if (mergeAccountToken) {
      mergeAccount(mergeAccountToken);
    }
  }, []);

  return (
    <>
      <FormHeader
        topLabel="Vous n'avez pas de compte ?"
        actionTopLabel='Inscrivez-vous ici'
        actionTopOnClick={() => navigate(`/subscription`)}
        title='Heureux de vous revoir !'
        proSanteConnect={true}
        franceConnect={false}
        getProSanteConnectAuthenticationUrl={getProSanteConnectAuthenticationUrl}
        getFranceConnectAuthenticationUrl={getFranceConnectAuthenticationUrl}
      />

      <FormProvider {...connexionMethods}>
        <form onSubmit={connexionMethods.handleSubmit(loginWithForm)}>
          <Controller
            name='nickNameOrEmail'
            control={connexionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Adresse mail ou identifiant'
                  type='text'
                  placeholder='Adresse mail ou identifiant'
                  actionLabel={
                    <span
                      className='cursor-pointer font-semibold text-green-secondary hover:underline'
                      onClick={() => navigate(`/reset-nickname`)}
                    >
                      Identifiant oublié ?
                    </span>
                  }
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                  // errorMessage={<>L'identifiant ne correspond à aucun compte. <span className="font-bold">Veuillez <span className="underline cursor-pointer" onClick={() => setPageStep(pageStep.includes('Participant') ? 'subscriptionParticipant' : 'subscription')}>créer un compte</span></span>.</>}
                />
              </>
            )}
          />
          <Controller
            name='password'
            control={connexionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Mot de passe'
                  type='password'
                  placeholder='Mot de passe'
                  actionLabel={
                    <span
                      className='cursor-pointer font-semibold text-green-secondary hover:underline'
                      onClick={() => navigate(`/reset-password`)}
                    >
                      Mot de passe oublié ?
                    </span>
                  }
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <BasicButton label='Se connecter' fullWidth={true} isSubmitButton={true} />
        </form>
      </FormProvider>
    </>
  );
};

export default LoginForm;
