import * as Icon from '../../../components/icons';

interface AuthSettingsItemProps {
  title: string;
  description: string;
  updatedAt: string;
  borderBottom?: boolean;
  actionOnClick: () => void;
}

const AuthSettingsItem = ({ title, description, updatedAt, borderBottom = false, actionOnClick }: AuthSettingsItemProps) => {
  return (
    <div className={`flex px-4 py-4 ${borderBottom && 'border-b border-b-[#DCE2EC]'} `} onClick={actionOnClick}>
      <div className='max-md:w-[95%] min-md:w-[65%]'>
        <div className='font-bold text-[#21577A]'>{title}</div>
        <div className='text-[#21577A]'>{description}</div>
      </div>
      <div className='my-auto w-[30%] text-center text-[#21577A] max-md:hidden'>{updatedAt}</div>
      <div className='my-auto w-[5%] cursor-pointer max-md:hidden'>
        <Icon.Pen />
      </div>
      <div className='my-auto ml-3 w-[5%] min-md:hidden'>
        <Icon.ArrowRight />
      </div>
    </div>
  );
};

export default AuthSettingsItem;
