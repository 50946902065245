import { FC, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import * as Icon from '../icons';

export type ToastProps = {
  timer?: number;
  remove: () => void;
  description?: string;
  type: 'SUCCESS' | 'ERROR';
};

export const Toast: FC<ToastProps> = ({ remove, timer, description, type }) => {
  const [timeProgress, setTimeProgress] = useState(timer ?? 0);
  const icon = type == 'SUCCESS' ? <Icon.Check /> : <Icon.Error className='w-20' color='#FFFFFF' />;

  useEffect(() => {
    if (!timer) return;
    setTimeout(() => {
      if (timeProgress <= 0) {
        remove();
      } else {
        setTimeProgress(timeProgress - 10);
      }
    }, 10);
  }, [timeProgress]);

  return (
    <div
      className={`w-80 ${type === 'SUCCESS' ? 'bg-green-dark' : 'bg-red-primary'} flex flex-col gap-y-2 rounded-lg border p-4 pb-2 shadow-lg`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-x-2'>
          {icon}
          <span className='text-sm text-white'>{description}</span>
        </div>
        <button className='scale-75' onClick={remove}>
          <Icon.Cross className='mr-2' color='#FFFFFF' />
        </button>
      </div>
      {timer && (
        <div className='flex h-1 w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700' role='progressbar'>
          <div
            className='flex w-[12%] flex-col justify-center overflow-hidden whitespace-nowrap bg-blue-600 text-center text-xs text-white dark:bg-gray-200'
            style={{ width: `${(timeProgress / timer) * 100}%` }}
          />
        </div>
      )}
    </div>
  );
};

export type ToastFunc = {
  addToast: (props: Omit<ToastProps, 'remove'>) => void;
};

export const ToastQueue = forwardRef((_, ref) => {
  const [toasts, setToasts] = useState<Omit<ToastProps, 'remove'>[]>([]);

  const handleRemove = (index: number) => {
    setToasts(toasts.filter((_, i) => i !== index));
  };

  useImperativeHandle(ref, () => ({
    addToast(props: Omit<ToastProps, 'remove'>) {
      setToasts([props, ...toasts]);
    },
  }));

  return (
    <div className='fixed right-4 top-4 z-50 w-80 bg-transparent'>
      <div className='flex flex-col gap-y-4'>
        {toasts.map((toast, index) => (
          <Toast key={index} remove={() => handleRemove(index)} {...toast} />
        ))}
      </div>
    </div>
  );
});

ToastQueue.displayName = 'ToastQueue';
