import { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Seo } from '../../components/common/Seo';
import { ToastFunc, ToastQueue } from '../../components/common/Toast';
import NicknameRequestForm from './forms/NicknameRequestForm';
import NicknameRequestSent from './NicknameRequestSent';
import NicknameResetForm from './forms/NicknameResetForm';
import NicknameResetSuccess from './NicknameResetSuccess';
import ErrorForm from '../../components/forms/ErrorForm';

const ResetNicknameComponent = () => {
  const toastQueueRef = useRef<ToastFunc>();
  const navigate = useNavigate();
  const searchQueryParams = useSearchParams()[0];
  const resetNicknameToken = searchQueryParams.get('resetNicknameToken');
  const [pageStep, setPageStep] = useState(resetNicknameToken ? 'emailVerified' : 'request');

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      <div className={`z-[10] w-full rounded-lg bg-white p-6`}>
        {
          {
            request: <NicknameRequestForm setPageStep={setPageStep} toastQueueRef={toastQueueRef} />,
            emailSend: <NicknameRequestSent />,
            emailVerified: (
              <NicknameResetForm setPageStep={setPageStep} resetNicknameToken={resetNicknameToken} toastQueueRef={toastQueueRef} />
            ),
            resetNicknameSuccess: <NicknameResetSuccess />,
            error: <ErrorForm onClick={() => navigate(`/login`)} />,
          }[pageStep]
        }
      </div>
      <Seo title={'Réinitialiser votre identifiant'} />
    </>
  );
};

export default ResetNicknameComponent;
