import { request } from './fetcher';

interface Login {
  nickNameOrEmail: string;
  password: string;
}
type LoginOTP = Login & {
  oneTimePassword: number;
};

export default class AuthService {
  async login(login: Login): Promise<{
    success: boolean;
    oneTimePasswordActivated: boolean;
    message: string;
    accessToken: string;
    refreshToken: string;
  }> {
    return request({
      method: 'post',
      url: `/auth/login`,
      data: {
        nickNameOrEmail: login.nickNameOrEmail,
        password: login.password,
      },
    });
  }

  async checkOneTimePassword(loginOTP: LoginOTP): Promise<{ accessToken: string; refreshToken: string }> {
    return await request({
      method: 'post',
      url: `/auth/check-one-time-password`,
      data: {
        nickNameOrEmail: loginOTP.nickNameOrEmail,
        password: loginOTP.password,
        oneTimePassword: loginOTP.oneTimePassword,
      },
    });
  }

  async getProSanteConnectLink(): Promise<{ authenticationUrl: string }> {
    return await request({
      method: 'get',
      url: `/auth/pro-sante-connect/connexion`,
    });
  }

  async proSanteConnectCheckUserInfo(code: string): Promise<{
    success: boolean;
    message: string;
    hasEmail: boolean;
    hasAppConnectEmail: boolean;
    accessToken: string;
    refreshToken: string;
    askMergeAccount: boolean;
    appToken: string;
  }> {
    return await request({
      method: 'post',
      url: `/auth/pro-sante-connect/checkUserInfo`,
      data: { code },
    });
  }

  async getFranceConnectLink(): Promise<{ authenticationUrl: string }> {
    return await request({
      method: 'get',
      url: `/auth/france-connect/connexion`,
    });
  }

  async franceConnectCheckUserInfo(code: string): Promise<{
    success: boolean;
    message: string;
    hasEmail: boolean;
    hasAppConnectEmail: boolean;
    accessToken: string;
    refreshToken: string;
    askMergeAccount: boolean;
    appToken: string;
  }> {
    return await request({
      method: 'post',
      url: `/auth/france-connect/checkUserInfo`,
      data: { code },
    });
  }

  async fcPscMergeAccount(appToken: string): Promise<{
    success: boolean;
  }> {
    return await request({
      method: 'post',
      url: `/auth/FC-PSC/mergeAccount`,
      data: { appToken },
    });
  }

  async resetNickname(email: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/auth/reset-nickname`,
      data: { email },
    });
  }

  async resetNicknameConfirm(token: string, nickName: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/auth/reset-nickname-confirm`,
      data: { token, nickName },
    });
  }

  async resetPassword(email: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/auth/reset-password`,
      data: { email },
    });
  }

  async resetPasswordConfirm(token: string, password: string): Promise<void> {
    return await request({
      method: 'post',
      url: `/auth/reset-password-confirm`,
      data: { token, password },
    });
  }
}
