import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Seo } from '../../components/common/Seo';
import * as Icon from '../../components/icons';
import MyApps from './myApps/MyApps';
import AuthSettings from './authSettings/AuthSettings';
import DashboardHeader from './layout/DashboardHeader';
import DashboardSidebar from './layout/DashboardSidebar';

const DashboardComponent = () => {
  const navigate = useNavigate();
  const searchQueryParams = useSearchParams()[0];

  const [tabActive, setTabActive] = useState<string>('authentication');
  const [authenticationTabPage, setAuthenticationTabPage] = useState<string>('');
  const [isTabContentHeightShort, setIsTabContentHeightShort] = useState<boolean>(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      navigate(`/login`);
    }

    if (window.innerHeight > document.documentElement.offsetHeight) {
      setIsTabContentHeightShort(true);
    } else {
      setIsTabContentHeightShort(false);
    }

    const mailUpdatedToken = searchQueryParams.get('mailUpdatedToken');
    if (mailUpdatedToken) {
      setTabActive('authentication');
      setAuthenticationTabPage('email-step2');
    }
  }, []);

  return (
    <>
      <div>
        <DashboardHeader authenticationTabPage={authenticationTabPage} setAuthenticationTabPage={setAuthenticationTabPage} />
        <div className='flex max-md:flex-col'>
          <DashboardSidebar tabActive={tabActive} setTabActive={setTabActive} setAuthenticationTabPage={setAuthenticationTabPage} />
          <div className={`rounded-l-3xl bg-[#F6F8FF] p-7 ${isTabContentHeightShort && 'h-screen'} min-md:w-[75%]`}>
            {authenticationTabPage && (
              <div className='mb-7 flex cursor-pointer max-md:hidden' onClick={() => setAuthenticationTabPage('')}>
                <Icon.ArrowCircleLeft className='mr-3' /> <span className='text-[#7988A2]'>Authentification</span>
              </div>
            )}
            {
              {
                authentication: (
                  <AuthSettings
                    authenticationTabPage={authenticationTabPage}
                    setAuthenticationTabPage={setAuthenticationTabPage}
                    setTabActive={setTabActive}
                  />
                ),
                myApps: <MyApps />,
              }[tabActive]
            }
          </div>
        </div>
      </div>
      <Seo title={'Dashboard'} />
    </>
  );
};

export default DashboardComponent;
