import React, { FC, SVGProps } from 'react';

export const Pen: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={21} height={22} viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        id='Subtract'
        opacity={0.5}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.541 4.66974L14.7923 2.41842C15.6626 1.54819 16.0977 1.11307 16.6078 0.972875C16.9547 0.877531 17.3209 0.877531 17.6678 0.972875C18.1779 1.11307 18.6131 1.54818 19.4833 2.4184L19.4833 2.41841C20.3535 3.28864 20.7886 3.72375 20.9288 4.23387C21.0242 4.58078 21.0242 4.94699 20.9288 5.2939C20.7886 5.80402 20.3535 6.23913 19.4833 7.10936L17.2537 9.33899C15.2984 8.21509 13.6759 6.60188 12.541 4.66974ZM11.0863 6.12442L2.74861 14.4621C2.32355 14.8872 2.11102 15.0997 1.97129 15.3608C1.83155 15.6219 1.77261 15.9166 1.65472 16.5061L0.866586 20.4468C0.800064 20.7794 0.766803 20.9457 0.861411 21.0403C0.956019 21.1349 1.12232 21.1016 1.45493 21.0351L5.3956 20.247L5.39562 20.247C5.98506 20.1291 6.27978 20.0701 6.54087 19.9304C6.80197 19.7907 7.0145 19.5782 7.43955 19.1531L7.43956 19.1531L15.7959 10.7968C13.8914 9.60887 12.283 8.00908 11.0863 6.12442Z'
        fill='#57A1A4'
      />
    </svg>
  );
};
