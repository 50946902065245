import React from 'react';
import FormHeader from '../../../components/forms/FormHeader';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { InputWord } from '../../../components/common/Input';
import { BasicButton } from '../../../components/common/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { requestSchema, requestType } from '../../../schema/ResetNicknameSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import AuthService from '../../../services/auth.service';
import { ToastFunc } from '../../../components/common/Toast';

type NicknameRequestFormProps = {
  setPageStep: React.Dispatch<React.SetStateAction<string>>;
  toastQueueRef: React.MutableRefObject<ToastFunc | undefined>;
};

const NicknameRequestForm = ({ setPageStep, toastQueueRef }: NicknameRequestFormProps) => {
  const navigate: NavigateFunction = useNavigate();
  const authService = new AuthService();

  const requestMethods = useForm<requestType>({
    resolver: yupResolver(requestSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const sendResetNicknameEmail = async ({ email }: requestType) => {
    try {
      await authService.resetNickname(email);

      setPageStep(`emailSend`);
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      } else {
        toastQueueRef.current?.addToast({
          type: 'ERROR',
          description: errData.message,
          timer: 5000,
        });
      }
    }
  };

  return (
    <>
      <FormHeader
        title={`Réinitialiser votre identifiant`}
        subTitle={`Merci de renseigner l’adresse e-mail associée à votre compte afin que nous puissions vous envoyer un lien de réinitialisation de votre identifiant.`}
      />
      <FormProvider {...requestMethods}>
        <form onSubmit={requestMethods.handleSubmit(sendResetNicknameEmail)}>
          <Controller
            name='email'
            control={requestMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Email'
                  type='text'
                  placeholder='Email'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <BasicButton label='Réinitialiser mon identifiant' fullWidth={true} isSubmitButton={true} />
          <div
            onClick={() => navigate(`/login`)}
            className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'
          >
            Retour à la page de connexion
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default NicknameRequestForm;
