import React from 'react';

type DashboardSidebarProps = {
  tabActive: string;
  setTabActive: React.Dispatch<React.SetStateAction<string>>;
  setAuthenticationTabPage: React.Dispatch<React.SetStateAction<string>>;
};

const DashboardSidebar = ({ tabActive, setTabActive, setAuthenticationTabPage }: DashboardSidebarProps) => {
  const activeTabCss =
    'text-[#21577A] font-bold min-md:bg-[#FFF6EB] min-md:border-r-4 min-md:border-r-[#F6A436] max-md:border-b-4 max-md:border-b-[#F6A436]';

  return (
    <div className='pt-20 max-md:flex min-md:w-[25%]'>
      <div
        className={`py-5 pl-5 max-md:mb-3 max-md:w-[50%] max-md:pr-5 max-md:text-center ${tabActive.includes('authentication') ? activeTabCss : 'cursor-pointer text-[#90ABBD]'} `}
        onClick={() => {
          setAuthenticationTabPage('');
          setTabActive('authentication');
        }}
      >
        Authentification
      </div>
      {/* <div
                    className={`
                        py-5 pl-5
                        max-md:pr-5 max-md:mb-3 max-md:w-[50%] max-md:text-center
                        ${tabActive === "lastConnexions" ? activeTabCss : "text-[#90ABBD] cursor-pointer"}
                    `}
                    onClick={() => setTabActive('lastConnexions')}
                >
                    Dernières connexions
                </div> */}
      <div
        className={`py-5 pl-5 max-md:mb-3 max-md:w-[50%] max-md:pr-5 max-md:text-center ${tabActive === 'myApps' ? activeTabCss : 'cursor-pointer text-[#90ABBD]'} `}
        onClick={() => setTabActive('myApps')}
      >
        Mes applications
      </div>
    </div>
  );
};

export default DashboardSidebar;
