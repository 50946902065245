import { NavigateFunction, useNavigate } from 'react-router-dom';
import FormHeader from '../../components/forms/FormHeader';

const NicknameResetSuccess = () => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <>
      <FormHeader
        title={`Identifiant réinitialisé avec succès`}
        subTitle={`Si vous souhaitez retourner à la page de connexion, veuillez cliquer ci-dessous.`}
      />
      <div onClick={() => navigate(`/login`)} className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'>
        Retour à la page de connexion
      </div>
    </>
  );
};

export default NicknameResetSuccess;
