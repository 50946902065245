import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import UsersService from '../../../services/users.service';
import { ToastFunc, ToastQueue } from '../../../components/common/Toast';
import { emailSchema, emailType } from '../../../schema/CheckUserSchema';
import {
  doubleAuthenticationSchema,
  doubleAuthenticationType,
  nicknameSchema,
  nicknameType,
  updateEmailSchema,
  updateEmailType,
  updatePasswordSchema,
  updatePasswordType,
} from '../../../schema/DashboardSchema';
import AuthSettingsItem from './AuthSettingsItem';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordUpdate from './forms/PasswordUpdate';
import NicknameUpdate from './forms/NicknameUpdate';
import EmailModal from './EmailModal';
import EmailUpdate1 from './forms/EmailUpdate1';
import EmailUpdate2 from './forms/EmailUpdate2';
import TFAUpdate from './forms/TFAUpdate';

interface AuthSettingsProps {
  authenticationTabPage: string;
  setAuthenticationTabPage: React.Dispatch<React.SetStateAction<string>>;
  setTabActive: React.Dispatch<React.SetStateAction<string>>;
}

const AuthSettings = ({ authenticationTabPage, setAuthenticationTabPage, setTabActive }: AuthSettingsProps) => {
  const usersService = new UsersService();
  const toastQueueRef = useRef<ToastFunc>();
  const [modaleVerificationEmailSend, setModaleVerificationEmailSend] = useState<boolean>(false);

  const emailMethods = useForm<emailType>({
    resolver: yupResolver(emailSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const updateEmailMethods = useForm<updateEmailType>({
    resolver: yupResolver(updateEmailSchema),
    mode: 'onSubmit',
    defaultValues: {
      newEmail: '',
      confirmNewEmail: '',
    },
  });

  const nicknameMethods = useForm<nicknameType>({
    resolver: yupResolver(nicknameSchema),
    mode: 'onSubmit',
    defaultValues: {
      nickname: '',
    },
  });

  const updatePasswordMethods = useForm<updatePasswordType>({
    resolver: yupResolver(updatePasswordSchema),
    mode: 'onSubmit',
    defaultValues: {
      actualPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  const doubleAuthenticationMethods = useForm<doubleAuthenticationType>({
    resolver: yupResolver(doubleAuthenticationSchema),
    mode: 'onSubmit',
    defaultValues: {
      activeSmsAuthentication: false,
      cellphone: '',
    },
  });

  const setDoubleAuthenticationData = async () => {
    const doubleAuthenticationData = await usersService.getDoubleAuthentication();

    doubleAuthenticationMethods.setValue('activeSmsAuthentication', doubleAuthenticationData.oneTimePasswordActivated);
    doubleAuthenticationMethods.setValue('cellphone', doubleAuthenticationData.cellphone);
  };

  useEffect(() => {
    if (authenticationTabPage === '') {
      emailMethods.reset();
      updateEmailMethods.reset();
      nicknameMethods.reset();
      updatePasswordMethods.reset();
      doubleAuthenticationMethods.reset();
    }

    if (authenticationTabPage === 'doubleAuthentication') {
      setDoubleAuthenticationData();
    }
  }, [authenticationTabPage]);

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      <div className='rounded-2xl bg-[#FFFFFF] p-7'>
        {
          {
            password: (
              <PasswordUpdate
                setTabActive={setTabActive}
                setAuthenticationTabPage={setAuthenticationTabPage}
                updatePasswordMethods={updatePasswordMethods}
                toastQueueRef={toastQueueRef}
              />
            ),
            nickName: (
              <NicknameUpdate
                setTabActive={setTabActive}
                setAuthenticationTabPage={setAuthenticationTabPage}
                nicknameMethods={nicknameMethods}
                toastQueueRef={toastQueueRef}
              />
            ),
            'email-step1': (
              <EmailUpdate1
                setTabActive={setTabActive}
                setAuthenticationTabPage={setAuthenticationTabPage}
                emailMethods={emailMethods}
                setModaleVerificationEmailSend={setModaleVerificationEmailSend}
                toastQueueRef={toastQueueRef}
              />
            ),
            'email-step2': (
              <EmailUpdate2
                setTabActive={setTabActive}
                setAuthenticationTabPage={setAuthenticationTabPage}
                updateEmailMethods={updateEmailMethods}
                toastQueueRef={toastQueueRef}
              />
            ),
            doubleAuthentication: (
              <TFAUpdate
                setTabActive={setTabActive}
                setAuthenticationTabPage={setAuthenticationTabPage}
                doubleAuthenticationMethods={doubleAuthenticationMethods}
                toastQueueRef={toastQueueRef}
              />
            ),
            '': (
              <>
                <AuthSettingsItem
                  title='Adresse e-mail'
                  description='Permet de recevoir des informations et instructions en cas de problème ou modification de votre compte.'
                  updatedAt=''
                  borderBottom={true}
                  actionOnClick={() => setAuthenticationTabPage('email-step1')}
                />
                <AuthSettingsItem
                  title='Identifiant'
                  description='Couplé à votre mot de passe, vous permet de vous connecter à votre compte.'
                  updatedAt=''
                  borderBottom={true}
                  actionOnClick={() => setAuthenticationTabPage('nickName')}
                />
                <AuthSettingsItem
                  title='Mot de passe'
                  description='Permet de confirmer votre identité à la connexion.'
                  updatedAt=''
                  borderBottom={true}
                  actionOnClick={() => setAuthenticationTabPage('password')}
                />
                <AuthSettingsItem
                  title='Authentification à double facteur'
                  description='Recevez un code par SMS à chaque connexion afin de renforcer la sécurité de votre compte.'
                  updatedAt=''
                  actionOnClick={() => setAuthenticationTabPage('doubleAuthentication')}
                />
              </>
            ),
          }[authenticationTabPage]
        }
      </div>
      <EmailModal
        setAuthenticationTabPage={setAuthenticationTabPage}
        modaleVerificationEmailSend={modaleVerificationEmailSend}
        setModaleVerificationEmailSend={setModaleVerificationEmailSend}
      />
    </>
  );
};

export default AuthSettings;
