import React, { useEffect, useState } from 'react';
import FormHeader from '../../../components/forms/FormHeader';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AuthService from '../../../services/auth.service';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { InputCheckbox, InputWord } from '../../../components/common/Input';
import UsersService from '../../../services/users.service';
import { subscriptionSchema, subscriptionType } from '../../../schema/SubscriptionSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { PasswordConstraint } from '../../../components/common/PasswordConstraint';
import { ToastFunc } from '../../../components/common/Toast';
import { BasicButton } from '../../../components/common/Button';

type SubscriptionFormProps = {
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setModaleCreatedAccountOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toastQueueRef: React.MutableRefObject<ToastFunc | undefined>;
};

const SubscriptionForm = ({ setHasError, setModaleCreatedAccountOpen, toastQueueRef }: SubscriptionFormProps) => {
  const navigate: NavigateFunction = useNavigate();
  const authService = new AuthService();
  const usersService = new UsersService();

  const subscriptionMethods = useForm<subscriptionType>({
    resolver: yupResolver(subscriptionSchema),
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      nickname: '',
      password: '',
      confirmPassword: '',
      acceptCGU: false,
      subscribeNewsletter: false,
    },
  });

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  type CheckPasswordSecurity = {
    twelveCharacters: boolean;
    oneLowerCaseCharacter: boolean;
    oneUpperCaseCharacter: boolean;
    oneNumberCharacter: boolean;
    oneSpecialCharacter: boolean;
  };

  const [checkPasswordSecurity, setCheckPasswordSecurity] = useState<CheckPasswordSecurity>({
    twelveCharacters: false,
    oneLowerCaseCharacter: false,
    oneUpperCaseCharacter: false,
    oneNumberCharacter: false,
    oneSpecialCharacter: false,
  });

  useEffect(() => {
    let checkSecurity = {
      twelveCharacters: subscriptionMethods.watch('password').length >= 12,
      oneLowerCaseCharacter: false,
      oneUpperCaseCharacter: false,
      oneNumberCharacter: false,
      oneSpecialCharacter: false,
    };

    for (let i = 0; i < subscriptionMethods.watch('password').length; i++) {
      checkSecurity = {
        ...checkSecurity,
        oneLowerCaseCharacter:
          checkSecurity.oneLowerCaseCharacter === true ? true : /^[a-z]$/.test(subscriptionMethods.watch('password').charAt(i)),
        oneUpperCaseCharacter:
          checkSecurity.oneUpperCaseCharacter === true ? true : /^[A-Z]$/.test(subscriptionMethods.watch('password').charAt(i)),
        oneNumberCharacter: checkSecurity.oneNumberCharacter === true ? true : /^\d$/.test(subscriptionMethods.watch('password').charAt(i)),
        oneSpecialCharacter:
          checkSecurity.oneSpecialCharacter === true ? true : /^\W$/.test(subscriptionMethods.watch('password').charAt(i)),
      };
    }

    setCheckPasswordSecurity(checkSecurity);
  }, [subscriptionMethods.watch('password')]);

  const getProSanteConnectAuthenticationUrl = async () => {
    try {
      const data = await authService.getProSanteConnectLink();
      document.location.href = data.authenticationUrl;
    } catch (error) {
      setHasError(true);
    }
  };

  const createUser = async ({ email, nickname, password, subscribeNewsletter }: subscriptionType) => {
    try {
      await usersService.createUser({
        email: email,
        nickName: nickname,
        password: password,
        subscribeNewsletter: subscribeNewsletter,
      });

      setModaleCreatedAccountOpen(true);
    } catch (error) {
      const errData = (error as AxiosError).response?.data as AxiosErrorDatas;

      if (errData.statusCode === 400) {
        toastQueueRef.current?.addToast({
          timer: 10000,
          description: errData.message,
          type: 'ERROR',
        });
      } else {
        setHasError(true);
      }
    }
  };

  return (
    <>
      <FormHeader
        topLabel='Déjà inscrit ?'
        actionTopLabel='Connectez-vous ici'
        actionTopOnClick={() => navigate(`/login`)}
        title='Créer votre compte'
        proSanteConnect={true}
        // franceConnect={true}
        getProSanteConnectAuthenticationUrl={getProSanteConnectAuthenticationUrl}
      />
      <FormProvider {...subscriptionMethods}>
        <form onSubmit={subscriptionMethods.handleSubmit(createUser)}>
          <Controller
            name='email'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Email'
                  type='text'
                  mandatory={true}
                  placeholder='Email'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <Controller
            name='nickname'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Identifiant'
                  type='text'
                  mandatory={true}
                  tooltip={true}
                  placeholder='Identifiant'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <Controller
            name='password'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Mot de passe'
                  type='password'
                  mandatory={true}
                  placeholder='Mot de passe'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <PasswordConstraint checkSecurity={checkPasswordSecurity} />
          <Controller
            name='confirmPassword'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputWord
                  label='Confirmer le mot de passe'
                  type='password'
                  placeholder='Mot de passe'
                  value={value}
                  onChangeValue={onChange}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <Controller
            name='acceptCGU'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputCheckbox
                  label={
                    <span>
                      J’ai pris connaissance et accepte les{' '}
                      <span
                        onClick={() => window.open('/SKEZI_-_CGU_V1.pdf', '_blank')}
                        className='cursor-pointer font-semibold text-green-secondary'
                      >
                        Conditions Générales d’Utilisation
                      </span>{' '}
                      et la{' '}
                      <span
                        onClick={() => window.open('/SKEZI_-_Politique_de_Confidentialite_V2.0.pdf', '_blank')}
                        className='cursor-pointer font-semibold text-green-secondary'
                      >
                        Politique de confidentialité
                      </span>
                    </span>
                  }
                  checked={value}
                  actionOnClick={() => onChange(!value)}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <Controller
            name='subscribeNewsletter'
            control={subscriptionMethods.control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <InputCheckbox
                  label={<>Je souhaite être informé des futures évolutions et actualités des solutions</>}
                  checked={value}
                  actionOnClick={() => onChange(!value)}
                  errorMessage={error?.message}
                />
              </>
            )}
          />
          <BasicButton label="S'inscrire" fullWidth={true} isSubmitButton={true} />
        </form>
      </FormProvider>
    </>
  );
};

export default SubscriptionForm;
